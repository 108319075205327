<app-header></app-header>

<app-sidebar [isExpanded]="isExpanded">
    <ng-container *ngIf="message$ | async as message">
        <app-sms-notice-banner  #messageBanner class="banner" [ngClass]="{'expand-banner-width':!isExpanded}"
            *ngIf="message.text != ''"  (closeBanner)="clearMessage()" [type]="message.type">
            <p>{{ message.text ? message.text : 'An error occurred, please try again' }}</p>
        </app-sms-notice-banner>
    </ng-container>

</app-sidebar>
