import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { IUser, IUserSettings } from '@data/user-profile/user-profile.interface';
import { userRole } from '@data/users/sms-users.interface';
import { environment } from '@env/environment';
import { catchError, shareReplay, map, first, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _loggedInUserSettings$ = new Subject<IUserSettings>();
  private _cachedLoggedInUserSettings: IUserSettings | null = null;

  constructor(
    private _http: HttpClient, 
    private _authService: AuthService
  ) { }

  public getUserView(userId: string) {
    return this._http.get((environment.api) + "user/" + userId).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getUserRoles(data: userRole, account_id: string) {
    return this._http.post((environment.api) + "account/" + account_id + "/role/search", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getUserRolePermissions() {
    const userId = this._authService.getToken().user_id;
    return this._http.get<IUser>((environment.api) + "user/" + userId + "?return_acl=1", { observe: 'response' })
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public updateExistingUser(data: any, userId: string) {
    return this._http.post((environment.api) + "user/" + userId, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getUserAccess(userId: string) {
    return this._http.get((environment.api) + "user/" + userId + "/userAccess").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getUserData(userId: string) {
    return this._http.get((environment.api) + "user/" + userId).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getUserSettings(userId: string) {
    return this._http.get<IUserSettings>((environment.api) + "user/" + userId + "/settings")
      .pipe(
        shareReplay(1),
        catchError((error) => { return throwError(() => error); })
      );
  }

  public updateUserSettings(data: IUserSettings, userId: string) {
    return this._http.post<IUserSettings>((environment.api) + "user/" + userId + "/settings", data)
      .pipe(
        catchError((error) => { return throwError(() => error); })
      );
  }

  public userCredentials(data: any, id: string) {
    return this._http.post((environment.api) + "user/" + id + "/userCredentials/search", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public updateExistingCredentials(userId: string, credentialsId: string, credentialsParams: any) {
    return this._http.post((environment.api) + "user/" + userId + "/userCredentials/" + credentialsId + "/changePassword", credentialsParams).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public deleteUser(userId: string) {
    return this._http.get((environment.api) + "user/" + userId + "/delete").pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public addCarrier(data: any, userId: string) {
    return this._http.post((environment.api) + "user/" + userId + "/usercarrier", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }
  public deleteCarrier(data: any) {
    return this._http.post((environment.api) + "user/usercarrier/deleteSelected", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public getAllUsers(data: any) {
    return this._http.post((environment.api) + "user/search", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public addNewUser(data: any) {
    return this._http.post((environment.api) + "user", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public accountOwnerNameSearch(data: any) {
    return this._http.post((environment.api) + "user/search", data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }

  public bulkDeleteUser(userIds: any) {
    return this._http.post((environment.api) + "user/bulkDelete", userIds).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    )
  }
  public userInvite(userId:string){
    return this._http.get((environment.api) + "user/"+userId+"/userInvite/").pipe(
      catchError((error) => {
        return throwError(() => error);
      }))
  }

  public selfServiceConfig(user_id: any, criteria: any) {
    return this._http.post((environment.api) + "user/" + user_id + "/selfServiceConfig/search/", criteria).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public selfServiceConfigUpdate(user_id: any, criteria: any) {
    return this._http.post((environment.api) + "user/" + user_id + "/selfServiceConfig/", criteria).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }
}
