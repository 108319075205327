<div class="lapro-notice-banner {{ type }} message-wrap-outer">
    <div class="message-left-wrap">
        <div class="notice-banner__icon" *ngIf="showIcon">
            <mat-icon *ngIf="type === 'announcement'" inline class="icon--small"><app-sms-spinner size="sm"></app-sms-spinner></mat-icon>
            <mat-icon *ngIf="type === 'alert'" inline class="icon--small">warning</mat-icon>
            <mat-icon *ngIf="type === 'info'" inline class="icon--small">info</mat-icon>
            <mat-icon *ngIf="type === 'success'" inline class="icon--small">check_circle</mat-icon>
            <mat-icon *ngIf="type === 'warn'" inline class="icon--small">info</mat-icon>
        </div>
        <div class="notice-banner__content">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="notice-banner__close">
        <mat-icon inline class="icon--small" (click)="closeNotice()">close</mat-icon>
    </div>
    
</div>
