<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="custom-modal" appDialogScroll>
    <div class="loading-indicator" *ngIf="isLoading">
        <app-sms-spinner></app-sms-spinner>
    </div>

    <div cdkDragHandle mat-dialog-title class="dialog-title" fxLayout="row wrap" fxLayoutAlign="space-between">
        <h2 class="text-upper">Lead Details<span *ngIf="lead_details"> - {{lead_details?.fname + ' ' + lead_details?.lname}}</span></h2>
        <button mat-icon-button (click)="captureClose()" aria-label="Close Modal"><mat-icon>close</mat-icon></button>
    </div>
    <div class="lead-detail-last-updated-panel">
        <app-lead-last-updated [lead_id]="lead_id"></app-lead-last-updated>
    </div>

    <mat-divider></mat-divider>

    <p class="update_msg" *ngIf="hasChange">Changes have been made. Select <a class="upperUpdateLink" title="Update Lead Detail Changes" (click)="updateLead('updateOnly')"><strong>"Update"</strong></a> to save them.</p>

    <mat-dialog-content class="mat-typography">
        <ng-container *ngIf="message.type">
            <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
                <p>{{ message.text }}</p>
            </app-sms-notice-banner>
        </ng-container>
        <div class="lapro-notice-banner alert" *ngIf="isFeetHeight">
            <div class="notice-banner__icon">
                <mat-icon inline class="icon--small">warning</mat-icon>
            </div>
            <div class="notice-banner__content"><span>The maximum height allowabale (in feet) is 8 feet. The application will not allow for values higher. The height has been set as 8 feet.</span></div>
        </div>
        <div class="lapro-notice-banner alert" *ngIf="isInchesHeight">
            <div class="notice-banner__icon">
                <mat-icon inline class="icon--small">warning</mat-icon>
            </div>
            <div class="notice-banner__content"><span>The maximum height allowabale (in inches) is 12 inches. The application will not allow for values higher.The height has been set as 12 inches.</span></div>
        </div>
        <div class="lapro-notice-banner alert" *ngIf="isWeight">
            <div class="notice-banner__icon">
                <mat-icon inline class="icon--small">warning</mat-icon>
            </div>
            <div class="notice-banner__content"><span>The maximum weight allowabale (in lbs) is 999 lbs. The application will not allow for values higher. The weight has been set as 999 lbs.</span></div>
        </div>

        <div class="basic-container lead-body">
            <form class="form-field-container" autocomplete="off" [formGroup]="leadDeatilsForm"
                fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between">
                <!-- Left Column -->
                <div class="leadDetails-col" fxFlex.gt-md="1 1 49%">
                    <!-- Lead Basic Info -->
                    <div>
                        <h4 class="user-profile-heading text-upper">Lead Information</h4>
                        <div class="form-field-container" fxLayout="column" fxLayout.gt-sm="row">
                            <mat-form-field fxFlex.gt-sm="1 1 3em">
                                <mat-label>Prefix</mat-label>
                                <input matInput formControlName="title" (mousedown)="$event.stopPropagation()" [maxlength]="6">
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-sm="3 1 0">
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName="fname" (mousedown)="$event.stopPropagation()" [maxlength]="25" appDisableAutofill>
                                <mat-hint *ngIf="leadDeatilsForm.value.fname.length == 25" class="error-msg">Maximum length is 25 characters</mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-sm="1 1 3em">
                                <mat-label>Initial</mat-label>
                                <input matInput formControlName="mname" (mousedown)="$event.stopPropagation()" [maxlength]="25">
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-sm="3 1 0">
                                <mat-label>Last Name</mat-label>
                                <input matInput formControlName="lname" (mousedown)="$event.stopPropagation()" [maxlength]="25" appDisableAutofill>
                                <mat-hint *ngIf="leadDeatilsForm.value.lname.length == 25" class="error-msg">Maximum length is 25 characters</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="form-field-container" fxLayout="column" fxLayout.gt-sm="row">
                            <mat-form-field fxFlex.gt-sm="3 1 0">
                                <mat-label>Birthdate</mat-label>
                                <input matInput formControlName="dob" [max]="maxDate" appDate (mousedown)="$event.stopPropagation()">
                                <mat-error *ngIf="checkError('dob', 'invalidDate')">You must enter a valid date.</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-sm="1 1 4em">
                                <mat-label>Gender</mat-label>
                                <mat-select disableOptionCentering matNativeControl formControlName="gender">
                                    <mat-option *ngFor="let gender of gender_data" value="{{gender.value}}" [innerHTML]="gender.text"></mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-sm="3 1 0">
                                <mat-label>Marital Status</mat-label>
                                <mat-select disableOptionCentering matNativeControl formControlName="marital_status">
                                    <mat-option *ngFor="let marital of marital_data" value="{{marital.value}}" [innerHTML]="marital.text"></mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div fxLayout="row" fxLayoutGap="1em" fxLayoutGap.gt-sm=".5em">
                                <mat-form-field fxFlex.lt-sm="1 1 50%" fxFlex.sm="1 1 50%" fxFlex.gt-sm="1 1 4em">
                                    <mat-label>Height</mat-label>
                                    <input matInput formControlName="height_feet" (input)="checkFeetHeight($event.target)" appNumbersOnly (mousedown)="$event.stopPropagation()">
                                    <span matSuffix>ft</span>
                                </mat-form-field>
                                <mat-form-field fxFlex.lt-sm="1 1 50%" fxFlex.sm="1 1 50%" fxFlex.gt-sm="1 1 3em">
                                    <input matInput formControlName="height_inches" (input)="checkInchesHeight($event.target)" appNumbersOnly (mousedown)="$event.stopPropagation()">
                                    <span matSuffix>in</span>
                                </mat-form-field>
                            </div>
                            <mat-form-field fxFlex.gt-sm="1 1 5em">
                                <mat-label>Weight</mat-label>
                                <input matInput formControlName="weight" (input)="checkWeight($event.target)" appNumbersOnly (mousedown)="$event.stopPropagation()">
                                <span matSuffix>lbs</span>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-sm="3 1 0">
                                <mat-label>Social Security</mat-label>
                                <input matInput formControlName="ssn" [maxLength]="11" appSocialSecurityMask (mousedown)="$event.stopPropagation()">
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Lead Phone number list -->
                    <app-phone *ngIf="isCommonFlag" formArrayName="phones" type="lead"></app-phone>

                    <!-- Lead Email list -->
                    <app-email *ngIf="isCommonFlag || !isLoading" formArrayName="emails" type="lead"></app-email>

                    <!-- Lead Address list -->
                    <app-address *ngIf="isCommonFlag" formArrayName="addresses" type="lead"></app-address>

                    <!-- Lead Preferred contact -->
                    <div>
                        <h4 class="user-profile-heading text-upper">Preferred Contact</h4>
                        <div class="form-field-container" fxLayout="column" fxLayout.gt-sm="row wrap">
                            <mat-form-field fxFlex.gt-md="1 1 0">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="contact_name" (mousedown)="$event.stopPropagation()" [maxlength]="50" appDisableAutofill>
                                <mat-hint *ngIf="leadDeatilsForm.value.contact_name.length == 50" class="error-msg">Maximum length is 50 characters</mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-md="1 1 0">
                                <mat-label>Type</mat-label>
                                <mat-select disableOptionCentering matNativeControl formControlName="contact_phone_type">
                                    <mat-option *ngFor="let contact of contact_data" value="{{contact.value}}" [innerHTML]="contact.text"></mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-md="1 1 0">
                                <mat-label>Telephone #</mat-label>
                                <input matInput formControlName="contact_phone" appPhoneNumberMask [maxlength]="14" (mousedown)="$event.stopPropagation()" appDisableAutofill>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-md="1 1 0">
                                <mat-label>Relationship</mat-label>
                                <mat-select disableOptionCentering matNativeControl formControlName="contact_relationship">
                                    <mat-option *ngFor="let relation of relation_data" value="{{relation.value}}" [innerHTML]="relation.text"></mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="lead-body-button">
                                <button mat-icon-button type="button" class="close" (click)="clearPreferredContact()" matTooltip="Clear Preferred Contact">
                                    <mat-icon inline class="_font--larger">close</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="1em">
                            <label>Use as emergency contact?</label>
                            <mat-radio-group formControlName="isEmergencyContact" (change)="radioChange($event)">
                                <mat-radio-button value="1">Yes</mat-radio-button>
                                <mat-radio-button value="0">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <!-- Lead Emergency contact -->
                    <div *ngIf="isEmergency">
                        <h4 class="user-profile-heading text-upper">Emergency Contact</h4>
                        <div class="form-field-container" fxLayout="column" fxLayout.gt-sm="row wrap">
                            <mat-form-field fxFlex.gt-md="1 1 0">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="emergency_name" (mousedown)="$event.stopPropagation()" [maxlength]="50" appDisableAutofill>
                                <mat-hint *ngIf="leadDeatilsForm.value.emergency_name.length == 50" class="error-msg">Maximum length is 50 characters</mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-md="1 1 0">
                                <mat-label>Type</mat-label>
                                <mat-select disableOptionCentering matNativeControl formControlName="emergency_phone_type">
                                    <mat-option *ngFor="let contact of contact_data" value="{{contact.value}}" [innerHTML]="contact.text"></mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-md="1 1 0">
                                <mat-label>Telephone #</mat-label>
                                <input matInput formControlName="emergency_phone" appPhoneNumberMask [maxlength]="14" (mousedown)="$event.stopPropagation()" appDisableAutofill>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-md="1 1 0">
                                <mat-label>Relationship</mat-label>
                                <mat-select disableOptionCentering matNativeControl formControlName="emergency_relationship">
                                    <mat-option *ngFor="let relation of relation_data" value="{{relation.value}}" [innerHTML]="relation.text"></mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="lead-body-button">
                                <button mat-icon-button type="button" class="close" (click)="clearEmergencyContact()" matTooltip="Clear Emergency Contact">
                                    <mat-icon inline class="_font--larger">close</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Column -->
                <div class="leadDetails-col" fxFlex.gt-md="1 1 49%">
                    <!-- Lead Medicare Card -->
                    <div>
                        <h4 class="user-profile-heading text-upper">Medicare Card</h4>
                        <div class="form-field-container" fxLayout="column" fxLayout.gt-sm="row wrap">
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Medicare Name</mat-label>
                                <input matInput formControlName="medicare_name" (mousedown)="$event.stopPropagation()" [maxlength]="50" appDisableAutofill>
                                <mat-hint *ngIf="leadDeatilsForm.value.medicare_name.length == 50" class="error-msg">Maximum length is 50 characters</mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Medicare Number</mat-label>
                                <input matInput formControlName="hicn" (mousedown)="$event.stopPropagation()" [maxlength]="15">
                                <mat-hint *ngIf="leadDeatilsForm.value.hicn.length == 15" class="error-msg">Maximum length is 15 characters</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="form-field-container" fxLayout="column" fxLayout.gt-sm="row wrap">
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Part A Date</mat-label>
                                <input matInput formControlName="medicare_parta_date" appDate [min]="partAB_MinDate" (mousedown)="$event.stopPropagation()">
                                    <mat-error *ngIf="leadDeatilsForm.get('medicare_parta_date')?.invalid">Part A date should be first of the month & can't be before 1965</mat-error>
                            </mat-form-field>
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Part B Date</mat-label>
                                <input matInput formControlName="medicare_partb_date" appDate [min]="partAB_MinDate" (mousedown)="$event.stopPropagation()">
                                    <mat-error *ngIf="leadDeatilsForm.get('medicare_partb_date')?.invalid">Part B date should be first of the month & can't be before 1965</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Lead Origination Data -->
                    <div>
                        <h4 class="user-profile-heading text-upper">Lead Origination Data</h4>
                        <div class="form-field-container" [ngClass]="checkForPermissions() ? 'not-allowed' : ''" fxLayout="column" fxLayout.gt-sm="row">
                            <!-- Lead Source -->
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Lead Source</mat-label>
                                <mat-select matNativeControl formControlName="source_vendor_id" [disabled]="checkForPermissions()">
                                    <mat-option *ngFor="let source of lead_vendor_data" value="{{source.lead_vendor_id}}" [innerHTML]="source?.lead_vendor_code + ' [' + source?.company + ']'"></mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Advertising Code -->
                            <mat-form-field [ngClass]="checkForPermissions() ? 'pointer-event' : ''"  fxFlex.gt-sm="1 1 0">
                                <mat-label>Advertising Code</mat-label>
                                <input matInput formControlName="source_adcode" [readonly]="checkForPermissions()" (mousedown)="$event.stopPropagation()" [appInputMaxLength]>
                            </mat-form-field>
                        </div>
                        <div class="form-field-container" [ngClass]="checkForPermissions() ? 'not-allowed' : ''" fxLayout="column" fxLayout.gt-sm="row">
                            <!-- B2B Partner ID -->
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Client/Partner/Affiliate</mat-label>
                                <mat-select matNativeControl formControlName="b2bpartner_id" (selectionChange)="b2bChange($event.value)" [disabled]="checkForPermissions()">
                                    <mat-option value=""></mat-option>
                                    <mat-option *ngFor="let b2b of b2bPartner_data" value="{{b2b.b2bpartner_id}}" [innerHTML]="b2b.company"></mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Tracking Code -->
                            <mat-form-field *ngIf="lodEnabled" fxFlex.gt-sm="1 1 0">
                                <mat-label>Tracking Code</mat-label>
                                <mat-select formControlName="source_code" [disabled]="checkForPermissions()">
                                    <ng-container *ngFor="let tCode of tracking_data">
                                        <mat-option *ngIf="tCode.active == '1'" value="{{tCode.tracking_code}}"
                                        [innerHTML]="tCode.tracking_code"></mat-option>
                                    </ng-container>
                                    <mat-option *ngIf="lead_details && lead_details.source_code && tracking_data.length==0" value="{{lead_details.source_code}}">{{lead_details.source_code}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="checkError('source_code', 'required')">Tracking Code is a required field.</mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="!lodEnabled" fxFlex.gt-sm="1 1 0">
                                <mat-label>Tracking Code</mat-label>
                                <input matInput formControlName="source_code">
                            </mat-form-field>
                        </div>
                        <div class="form-field-container" [ngClass]="checkForPermissions() ? 'not-allowed' : ''" fxLayout="column" fxLayout.gt-sm="row">
                            <!-- Campaign ID -->
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Campaign</mat-label>
                                <mat-select matNativeControl formControlName="campaign_id" (selectionChange)="changeCampaign($event.value)" [disabled]="checkForPermissions()">
                                    <mat-option value=""></mat-option>
                                    <mat-option *ngFor="let camp of campaign_code" value="{{camp.campaign_id}}" [innerHTML]="camp.campaign_code"></mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Campagn Segment ID -->
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Segment</mat-label>
                                <mat-select matNativeControl formControlName="campaign_segment_code" [disabled]="checkForPermissions()">
                                    <mat-option *ngFor="let seg of campaign_segment" value="{{seg.campaign_segment_code}}" [innerHTML]="seg.campaign_segment_code"></mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-field-container" [ngClass]="checkForPermissions() ? 'not-allowed' : ''" fxLayout="column" fxLayout.gt-sm="row">
                            <!-- Lead Type ID -->
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Lead Type</mat-label>
                                <mat-select matNativeControl formControlName="lead_type_id" [disabled]="checkForPermissions()">
                                    <mat-option *ngFor="let type of lead_type" value="{{type.lead_type_id}}" [innerHTML]="type.lead_type_desc | titlecase"></mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Product Type -->
                            <mat-form-field fxFlex.gt-sm="1 1 0">
                                <mat-label>Product Type</mat-label>
                                <mat-select matNativeControl formControlName="product_type_id" [disabled]="checkForPermissions()">
                                    <mat-option *ngFor="let product of product_type" value="{{product.product_type_id}}" [innerHTML]="product.product_type_name | titlecase"></mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Lead Contact Instuctions -->
                    <div>
                        <!-- Lead Instruction Info -->
                        <div class="lead-ins">
                            <h4 class="user-profile-heading text-upper">Lead Instruction</h4>
                            <ul *ngIf="lead_details?.lead_instructions">
                                <li *ngFor="let ins of lead_details?.lead_instructions" [innerHTML]="ins.lead_instruction_text"></li>
                            </ul>
                            <!-- Tags -->
                            <div class="form-field-container" fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start start">
                                <mat-form-field fxFlex.gt-md="1 1 0">
                                    <mat-label>Tag</mat-label>
                                    <mat-chip-list #chipList aria-label="Tag selection">
                                        <mat-chip *ngFor="let tag of tags" (removed)="removeTag(tag)">
                                            <span>{{tag.tag_name}}</span>
                                            <button matChipRemove><mat-icon>cancel</mat-icon></button>
                                        </mat-chip>
                                        <input placeholder="" class="tag-input" #tagInput formControlName="tags" [matAutocomplete]="auto" [matChipInputFor]="chipList" [maxlength]="20" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTag($event,'')">
                                        <mat-error *ngIf="leadDeatilsForm.value.tags.length == 20" class="max-length-error">Maximum length is 20 characters</mat-error>
                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTagName" (optionSelected)="selected($event)">
                                        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">{{tag.tag_name}}</mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <button mat-button mat-raised-button color="primary" [disabled]="leadDeatilsForm.value.tags ==''" (click)="addTag(leadDeatilsForm.value.tags,'btn')">ADD TAG</button>
                            </div>
                        </div>

                        <!-- Contact Permissions  -->
                        <div>
                            <h4 class="user-profile-heading text-upper">Contact Permissions</h4>
                            <div class="contact-permissions form-field-container lead-ins">
                                <p>I attest that I received client's permission to contact regarding <strong>Medicare Advantage</strong> and <strong>Medicare Part D</strong> via:</p>
                                <div fxLayout="row wrap" fxLayoutAlign="start">
                                    <mat-checkbox formControlName="do_not_email">Email</mat-checkbox>
                                    <mat-checkbox formControlName="do_not_call">Phone</mat-checkbox>
                                    <mat-checkbox formControlName="do_not_text">Text</mat-checkbox>
                                    <mat-checkbox formControlName="do_not_mail">Mail</mat-checkbox>
                                </div>
                                <span>Last updated by: {{ lead_details?.agent_fname + ' ' + lead_details?.agent_lname }}</span>
                                <a class="linked-td" (click)="openPermissionHistory()" title="Show Permission History">Permission History</a>
                            </div>
                        </div>

                        <!-- Lead Disposition -->
                        <div>
                            <h4 class="user-profile-heading text-upper">Latest Disposition</h4>
                            <div class="form-field-container" fxLayout="column" fxLayout.gt-sm="row wrap">
                                <mat-form-field fxFlex.gt-sm="1 1 0">
                                    <mat-label>Date</mat-label>
                                    <input matInput formControlName="disposition_date" [matDatepicker]="disposition_date" appDate readonly class="not-allowed">
                                    <mat-datepicker-toggle matSuffix [for]="disposition_date"></mat-datepicker-toggle>
                                    <mat-datepicker #disposition_date></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field fxFlex.gt-sm="1 1 0">
                                    <mat-label>Title</mat-label>
                                    <input matInput formControlName="dispo_name" readonly class="not-allowed">
                                </mat-form-field>
                                <mat-form-field fxFlex.gt-sm="1 1 0">
                                    <mat-label>Description</mat-label>
                                    <input matInput formControlName="dispo_log" readonly class="not-allowed">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-raised-button (click)="closeModal()">{{hasChange ? 'Cancel' : 'Close' }}</button>
        <button *ngIf="hasChange" mat-button mat-raised-button (click)="updateLead('updateOnly')" color="primary">Update</button>
        <button *ngIf="hasChange" mat-button mat-raised-button (click)="updateLead('close')" color="primary">Update + Close</button>
    </mat-dialog-actions>
</div>
