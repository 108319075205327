import { Component, Input, OnInit, ViewChild, HostListener, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/service/auth.service';
import { LeadService } from '@data/services/lead/lead.service';
import { AddEventComponent } from '@modules/calendar/pages/add-event/add-event.component';
import { NewLeadComponent } from '@modules/lead/components/new-lead/new-lead.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import packageInfo from '../../../../package.json';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from '@shared/services/common/common.service';
import { filter, first, Subject, takeUntil } from 'rxjs';

import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { LeadDetail } from '@data/lead/lead.interface';
import { ViewSwapperService } from '@data/services/quotes/view-swapper.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  private flag: boolean = false; //flag to check popover open ot not
  private _componentDestroyed$ = new Subject<boolean>();

  public versionNumber: string;
  public quote_url: string = '/quotes';
  public admin_url: string = '/admin/user/manage_user';
  public lead_url: string = '/lead';
  public report_url: string = '/reports/self-service'
  public form_url: string = '/forms/medicare';
  public showSidebar: boolean;
  public navigateURL : string;
  public leadDetails: LeadDetail | null;

  @Input() isExpanded: boolean = false;
  @ViewChild(QuickLinksComponent, { static: false })
  quickLinkComponent!: QuickLinksComponent;
  @ViewChild('sidenav') sidenav: MatSidenavModule;
  @ViewChild('panel', { static: true }) private sidePanel: MatSidenav;
  @ViewChild('content', { static: true, read: ViewContainerRef }) private vcf: ViewContainerRef;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private toastMsgService : ToastMsgService,
    public authService: AuthService,
    public leadService: LeadService,
    public commonService: CommonService,
    private sidenavService: ViewSwapperService
  ) {
    this.versionNumber = packageInfo.appRelease || '';

    this.leadService.changedLead()
      .pipe(
        takeUntil(this._componentDestroyed$),
      )
      .subscribe({
        next: (leadDetails) => {
          this.leadDetails = leadDetails
        }
      })

    this.router.events
      .pipe(
        takeUntil(this._componentDestroyed$),
        filter(event => event instanceof NavigationEnd),
        first(),
      ).subscribe(navigation => {
        const url = (navigation as NavigationEnd).url
        this.showSidebar = url.indexOf('registration') < 0;
        this.navigateURL = url;
      });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event:any): void {
    if (this.sidenav !== undefined) {
      if (event.target.innerWidth <= 980) { // This can be any value.
        this.isExpanded = false;
      } else {
        this.isExpanded = true;
      }
      if (event.target.innerWidth <= 665) {
        this.commonService.setDashboardUI(true);
      } else {
        this.commonService.setDashboardUI(false);
      }
      this.commonService.setMenuExpand(this.isExpanded);
      this.toastMsgService.isExpanded = this.isExpanded;
    }
  }

  public ngOnInit(): void {
    this.sidenavService.setPanel(this.sidePanel);
    this.sidenavService.setContentVcf(this.vcf);
  }

  public ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }

  public openQuickLinks() {
    this.dialog.open(QuickLinksComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  public addLead() {
    window.scroll(0, 0);
    const dialogRef = this.dialog.open(NewLeadComponent, {
      width: '60%',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe((result) => { });
  }
  public addEvent() {
    window.scroll(0, 0);
    let obj = {
      tabName: 'Appointment',
      type: 'add',
      ...(this.router.url == '/') && { activeModule : 'dashboard'}
    }
    const dialogRef = this.dialog.open(AddEventComponent, {
      width: '55%',
      autoFocus: false,
      disableClose: true,
      data: obj
    });
  }

  public addQuote(isLead: boolean = false) {
    this.leadService.leadSelected = isLead;
    this.quote_url = isLead ? '/quotes/mapartd/' + this.leadDetails?.lead_id : '/quotes';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.quote_url]);
    });
  }
  public redirectTo(url: string) {
    const currentUrl = url;
    if (currentUrl == '/quotes') {
      this.leadService.leadSelected = false;
    }

    // code to navigate to the selected tab
    this.router.navigateByUrl(currentUrl, { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      })
    });

  }

  public isActiveUrl(activeTab: string) {
    if (activeTab == '/lead') {
      if (this.router.url.startsWith('/lead')) {
        return true;
      } else {
        return false;
      }
    } else {
      return this.router.url.includes(activeTab);
    }
  }
}
