import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/service/auth.service';
import { QuickLinksService } from '@data/services/quick-links/quick-links.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-quick-link',
  templateUrl: './add-quick-link.component.html',
  styleUrls: ['./add-quick-link.component.scss']
})
export class AddQuickLinkComponent implements OnInit {

  public addLinkForm: FormGroup;
  public availableToEdit: boolean = false;
  public isLoading = false;
  public errorMessage = '';

  constructor(
    private fb: FormBuilder,
    private qlService: QuickLinksService,
    private toastMsgService: ToastMsgService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<AddQuickLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    let quicklink_hierarchy_type_id = (data?.quicklink_hierarchy_type_name == 'Account');

    this.addLinkForm = this.fb.group({
      quicklink_title: [data && data?.quicklink_title ? data?.quicklink_title : '', [Validators.required, Validators.maxLength(100)]],
      quicklink_url: [data && data?.quicklink_url ? data?.quicklink_url : '', [Validators.required, Validators.maxLength(200)]],
      quicklink_hierarchy_type_id: [{ value: quicklink_hierarchy_type_id, disabled: data ? true : false }],
    });

  }

  public ngOnInit(): void {
    this.availableToEdit = (this.authService.user_details?.role_name.indexOf("Admin") > -1);
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.addLinkForm.controls[controlName].hasError(errorName);
  }

  public saveLink() {
    this.isLoading = true;
    this.errorMessage = '';

    let quickLinkUrl: string = "";

    quickLinkUrl = (this.addLinkForm.value?.quicklink_url.startsWith('http://')
      || this.addLinkForm.value?.quicklink_url.startsWith('https://'))
      ? this.addLinkForm.value?.quicklink_url
      : "http://" + this.addLinkForm.value?.quicklink_url;

    let quicklink_hierarchy_type_id = !this.data ?
      (this.addLinkForm.value?.quicklink_hierarchy_type_id ? 2 : 3)
      : (this.data?.quicklink_hierarchy_type_name == 'Account' ? 2 : 3);

    let params = {
      quicklink_title: this.addLinkForm.value?.quicklink_title,
      quicklink_url: quickLinkUrl,
      quicklink_hierarchy_type_id: quicklink_hierarchy_type_id
    }

    if (!this.data) {
      if (quicklink_hierarchy_type_id == 2) {
       this.qlService.addAccountQuickLink(params)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            this._showToast("Quick Link created successfully");
          },
          error: (err: HttpErrorResponse) => {
            this._showError(err.error.message);
          }
        });

      } else {
       this.qlService.addUserQuickLink(params)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            this._showToast("Quick Link created successfully");
          },
          error: (err: HttpErrorResponse) => {
            this._showError(err.error.message);
          }
        });

      }

    } else {
      if (quicklink_hierarchy_type_id == 2) {
       this.qlService.updateAccountQuickLink(params, this.data?.quicklink_id)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            this._showToast("Quick Link updated successfully");
          },
          error: (err: HttpErrorResponse) => {
            this._showError(err.error.message);
          }
        });

      } else {
       this.qlService.updateUserQuickLink(params, this.data?.quicklink_id)
        .pipe(first())
        .subscribe({
          next: (res: any) => {
            this._showToast("Quick Link updated successfully");
          },
          error: (err: HttpErrorResponse) => {
            this._showError(err.error.message);
          }
        });

      }
    }
  }

  private closeDialog() {
    this.dialogRef.close(true);
  }

  private _showToast(msg: string) {
    this.isLoading = false;
    this.toastMsgService.showSuccess(msg);
    this.closeDialog();
  }

  private _showError(msg: string) {
    this.isLoading = false;

    this.errorMessage = msg;
    setTimeout(() => {
      this.errorMessage = '';
    }, 5000);
  }


}
