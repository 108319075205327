<mat-sidenav-container autosize>
    <mat-sidenav #sidenav *ngIf="showSidebar" fixedInViewport="true" fixedTopGap="55" [ngClass]="isExpanded ? 'w-60' : 'w-14'"
        mode="side" opened="true" [disableClose]="true">

        <div class="quick-links-button" *ngIf="isExpanded; else isCollapsed">
            <button type="button" id="qlBtn" class="quick-links" mat-flat-button color="primary" (click)="openQuickLinks()">QUICK LINKS</button>
        </div>

        <ng-template #isCollapsed>
            <button id="qlBtn" class="small-quick-link" mat-icon-button color="primary" matTooltip="Quick Links" (click)="openQuickLinks()">
                <mat-icon mat-list-icon>expand_more</mat-icon>
            </button>
        </ng-template>

        <mat-nav-list *ngIf="!isExpanded">
            <mat-list-item>
                <button mat-icon-button aria-label="Quick Actions" [matMenuTriggerFor]="quickAddMenu"><mat-icon>add</mat-icon></button>
            </mat-list-item>
        </mat-nav-list>
        <mat-menu #quickAddMenu="matMenu">
            <ng-template matMenuContent>
              <button mat-menu-item (click)="addLead()">Create New Lead</button>
              <button mat-menu-item (click)="addEvent()">Create New Calendar Event</button>
              <button mat-menu-item *ngIf="leadDetails" (click)="addQuote(true)">Create Quote for <strong>{{leadDetails.fname + ' ' + leadDetails.lname}}</strong></button>
              <button mat-menu-item (click)="addQuote()">Create New Quote</button>
            </ng-template>
        </mat-menu>

        <span *ngIf="isExpanded">
            <mat-nav-list>
                <p class="sidebar-menu">MENU</p>
            </mat-nav-list>
        </span>

        <mat-nav-list>
            <a mat-list-item title="Dashboard" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <mat-icon mat-list-icon>dashboard</mat-icon>
                <span class="full-width" *ngIf="isExpanded">Dashboard</span>
            </a>

            <a mat-list-item title="Leads" routerLinkActive="active"
                [ngClass]="{'active':isActiveUrl('/lead')}">
                <span class="btn-position" (click)="redirectTo(lead_url)">
                    <mat-icon mat-list-icon >group</mat-icon>
                    <span class="full-width" *ngIf="isExpanded">Leads</span>
                </span>
                <button *ngIf="isExpanded" (click)="addLead()" mat-mini-fab color="basic" class="add-new" aria-label="Add Lead">
                    <mat-icon class="sub-icon">add</mat-icon>
                </button>
            </a>

            <a mat-list-item title="Calendar" [routerLinkActive]="['active']">
                <a class="btn-position" [routerLink]="['/calendar']">
                    <mat-icon mat-list-icon>calendar_month</mat-icon>
                    <span class="full-width" *ngIf="isExpanded">Calendar</span>
                </a>
                <button *ngIf="isExpanded" (click)="addEvent()" mat-mini-fab color="basic" class="add-new" aria-label="Add Task/Appointment">
                    <mat-icon class="sub-icon">add</mat-icon>
                </button>
            </a>

            <a mat-list-item title="Quotes" [routerLinkActive]="['active']" [ngClass]="{'active':isActiveUrl('quotes/')}">
                <span class="btn-position" (click)="redirectTo(quote_url)">
                    <mat-icon mat-list-icon>event</mat-icon>
                    <span class="full-width" *ngIf="isExpanded">Quotes</span>
                </span>
                <button *ngIf="isExpanded" mat-mini-fab color="basic" class="add-new" aria-label="Create Quote" [matMenuTriggerFor]="quickQuoteMenu">
                    <mat-icon class="sub-icon">add</mat-icon>
                </button>
            </a>

            <mat-menu #quickQuoteMenu="matMenu">
                <ng-template matMenuContent>
                  <button mat-menu-item *ngIf="leadDetails" (click)="addQuote(true)">Create Quote for <strong>{{leadDetails.fname + ' ' + leadDetails.lname}}</strong></button>
                  <button mat-menu-item (click)="addQuote()">Create New Quote</button>
                </ng-template>
            </mat-menu>

            <a mat-list-item (click)="redirectTo(form_url)" title="Forms" routerLinkActive="active"
                [ngClass]="{'active':isActiveUrl('forms/')}">
                <span class="full-width" *ngIf="isExpanded">Forms</span>
                <mat-icon mat-list-icon>description</mat-icon>
            </a>

            <a mat-list-item (click)="redirectTo(report_url)" title="Reports" routerLinkActive="active"
                [ngClass]="{'active':isActiveUrl('/reports')}">
                <mat-icon mat-list-icon >bar_chart</mat-icon>
                <span class="full-width" *ngIf="isExpanded">Reports</span>
            </a>


        </mat-nav-list>

        <div [ngClass]="isExpanded ? 'seprate-section' : ''">
            <mat-nav-list>
                <a mat-list-item title="Admin" *ngIf="commonService.hasPermission('ui_admin')" (click)="redirectTo(admin_url)" [ngClass]="{'active':isActiveUrl('/admin')}">
                    <span class="full-width" *ngIf="isExpanded">Admin</span>
                    <mat-icon mat-list-icon>admin_panel_settings</mat-icon>
                </a>
                <a mat-list-item title="Help" [routerLink]="['/help']" [routerLinkActive]="['active']">
                    <span class="full-width" *ngIf="isExpanded">Help</span>
                    <mat-icon mat-list-icon>help_outline</mat-icon>
                </a>
                <a mat-list-item title="Privacy Policy" class="privacy-policy" [routerLink]="['/privacy_policy']" [routerLinkActive]="['active']">
                    <span class="full-width" *ngIf="isExpanded">Privacy Policy</span>
                </a>
            </mat-nav-list>
            <p *ngIf="isExpanded" class="version">{{'v'+versionNumber}}</p>
        </div>


    </mat-sidenav>

   <!-- this is the sidenav we are concerned with -->
   <mat-sidenav #panel class="panel" [disableClose]="true" position="end">
    <!-- this is where i want to load different components dynamically -->
    <ng-container #content></ng-container>
  </mat-sidenav>

    <div class="p-1" [class.container-bgc]="navigateURL == '/lead/search'">
        <ng-content></ng-content>
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>
