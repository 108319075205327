import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/service/auth.service';
import { LeadService } from '@data/services/lead/lead.service';
import moment from 'moment';
import { Observable, Subject, Subscription, first, map, BehaviorSubject, take, takeUntil } from 'rxjs';
import * as stateData from '@data/states.json';
import { UserDetails } from '@data/auth/auth.interface';
import { AccountSettings } from '@data/admin/account-settings.interface';
import { AccountService } from '@data/services/admin/account.service';
import { IUser, IUserSettings } from '@data/user-profile/user-profile.interface';
import { IProductFilter } from '@data/admin/product.interface';
import { UserService } from '@data/services/user/user.service';
import { Notice } from '@data/notice/notice.interface';
import { validatorPatterns } from '@app/constants';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { SingleSignOnComponent } from '@modules/quotes/components/single-sign-on/single-sign-on.component';
import { environment } from '@env/environment';
import { LaunchdarklyService } from '../LaunchDarkly/launchdarkly.service';
import {Router} from '@angular/router';
import { LeadDataDiscrepancyComponent } from '@modules/quotes/components/lead-data-discrepancy/lead-data-discrepancy.component';
@Injectable({
  providedIn: 'root',
})
export class CommonService implements OnDestroy {
  private alert = new Subject<any>();
  private expand = new Subject<any>();
  private isDashboard = new Subject<any>();
  private isLoader = new Subject<any>();
  private message: any = {};
  private state_data: any = [];
  private subject = new Subject<any>();
  private _componentDestroyed$ = new Subject<boolean>();

  private _userDetailSub$: Subscription;
  private _userDetails: UserDetails;
  private _accountId: string;
  private inAppNavigation:boolean=false;
  private configurableSettings :boolean = false;
  public configurableSettingsFlag = new Subject<boolean>;
  public tempPollingInterval!: number;
  public additionalFilterDataObj: any = {};

  // these are for the logged in user. do not update values with another user
  public userSettings$ = new Subject<IUserSettings>();
  public accountSettings$ = new Subject<AccountSettings>();

  private _loggedInAccountSettings: AccountSettings;
  private _loggedInUserSettings: IUserSettings;

  constructor(
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder,
    private router: Router,
    private ld: LaunchdarklyService,
    private authService: AuthService,
    private _accountSvc: AccountService,
    private _userService: UserService,
    private _leadService: LeadService,
    public dialog: MatDialog,
  ) {
    /* get states data */
    this.state_data = (stateData as any).default;
    this._userDetailSub$ = this.authService.authUser.subscribe({
      next: (userDetails) => {
        if (userDetails !== null)
          this._userDetails = userDetails;
      }
    });

    //Set inApp variable using launchdarkly flag
    this.ld.leadSwitchFlagChange.pipe(takeUntil(this._componentDestroyed$)).subscribe((flag:any) => {
      this.inAppNavigation = flag;
    });

    this.ld.configurableSettingsFlagChange.pipe(takeUntil(this._componentDestroyed$)).subscribe((flag:any) => {
     this.configurableSettings = flag;
     this.configurableSettingsFlag.next(flag);
    });
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    if (this._userDetailSub$)
      this._userDetailSub$.unsubscribe();
  }

  public isConfigSettingflagAvailable(){
    return this.configurableSettings;
  }

  public getLoggedInUserSettings(userId: string) : Observable<IUserSettings> {
    const cachedUserSettings = this._loggedInUserSettings;

    if (!cachedUserSettings && userId)
      return this._userService.getUserSettings(userId)
        .pipe(
          map( userSettings => {
            this._loggedInUserSettings = userSettings;
            this.userSettings$.next(userSettings);

            return userSettings;
          })
        );
    else
      setTimeout(() => {
        this.userSettings$.next(cachedUserSettings);
      });

    return this.userSettings$.asObservable();
  }

  public updateLoggedInUserSettings(userId: string, payload: IUserSettings) : Observable<IUserSettings> {
    return this._userService.updateUserSettings(payload, userId)
      .pipe(
        map( userSettings => {
          this._loggedInUserSettings = userSettings;
          this.userSettings$.next(userSettings);

          return userSettings;
        })
      );
  }

  public getAccountSettings(accountId: string = '') {
    return this._accountSvc.getAccountSettings(accountId);
  }

  public getLoggedInAccountSettings(accountId: string, force ?: boolean) : Observable<AccountSettings> {
    const cachedAccountSettings = this._loggedInAccountSettings;

    if ((!cachedAccountSettings && accountId) || force === true)
      return this._accountSvc.getAccountSettings(accountId)
        .pipe(
          map( accountSettings => {
            this._loggedInAccountSettings = accountSettings;
            this.accountSettings$.next(accountSettings);

            return accountSettings;
          })
        );
    else
      setTimeout(() => {
        this.accountSettings$.next(cachedAccountSettings)
      });

    return this.accountSettings$.asObservable();
  }

  public setAccountSettings(accountId: string, payload: AccountSettings): Observable<boolean> {
    const observable = new Subject<boolean>();
    this._accountSvc.updateAccountSettings(accountId, payload)
      .pipe(first())
      .subscribe({
        next: (settings: AccountSettings) => {
          observable.next((settings.success != '0'));
        }
      });

    return observable;
  }

  public updateLoggedInAccountSettings(accountId: string, payload: AccountSettings) {
    return this._accountSvc.updateAccountSettings(accountId, payload)
      .pipe(
        map( accountSettings => {
          this._loggedInAccountSettings = accountSettings;
          this.accountSettings$.next(accountSettings);

          return accountSettings;
        })
      );
  }

  public setLoading(isLoading:boolean){
    this.isLoader.next(isLoading);
  }

  public getLoading(): Observable<any> {
    return this.isLoader.asObservable();
  }

  public setDashboardUI(isExpand: boolean) {
    this.isDashboard.next(isExpand);
  }

  public getDashboardUI(): Observable<any> {
    return this.isDashboard.asObservable();
  }

  public setAccountId(id: string) {

    this._accountId = id;
  }

  public getAccountId() {
    return this._accountId;
  }

  public setAlert(data: any) {
    this.alert.next(data);
  }

  public goToLeadLedger(leadId:string){
    if(this.inAppNavigation){
      console.info("In app navigation")
      this.router.navigate(['lead/' + leadId]);
    }else{
      console.info("Browser navigation")
      window.location.href = 'lead/' + leadId;
    }
  }

  public getAlert(): Observable<any> {
    return this.alert.asObservable();
  }

  public formatYYYYMMDD(date: string) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  public toServerDate(input: any) {
    return input ? moment(input).toISOString() : undefined;
  }

  /* function to bind the formarray data of email from API */
  public setExistingEmail(data: any, default_email_id: string, type : string) {
    const formArray: any = new FormArray([]);
    if (data) {
      data.forEach((element: any) => {
        if (default_email_id) {
          if (element.email_id == default_email_id) {
            this.emailDataFilled(formArray,element,type,default_email_id)
          }else{
            this.emailDataFilled(formArray,element,type, '')
          }
        } else {
          this.emailDataFilled(formArray,element,type,'')
        }
      });
      return formArray;
    } else {
      formArray.push(this.fb.group({
        email: type === 'notReq' ? new FormControl('', [Validators.pattern(validatorPatterns.EMAIL)]) : new FormControl('', [Validators.required, Validators.pattern(validatorPatterns.EMAIL)]),
        email_type: ['Home'],
        email_id: "",
        email_class: "",
        isSelected: ""
      }));
      return formArray;
    }
  }

  private emailDataFilled(formArray:any, element:any,type:string,default_email_id:string){
    return formArray.push(this.fb.group({
      email: type === 'notReq' ? new FormControl(element.email, [Validators.pattern(validatorPatterns.EMAIL)]) : new FormControl(element.email, [Validators.required, Validators.pattern(validatorPatterns.EMAIL)]),
      email_type: element.email_type ? element.email_type[0].toUpperCase() + element.email_type.slice(1) : '',
      email_id: element.email_id,
      email_class: "",
      isSelected: element.email_id == default_email_id ? true : ""
    }));
  }

  /* function to bind the formarray data of phone from API */
  public setExistingPhone(data: any, default_phone_id: string, type : string) {
    const formArray: any = new FormArray([]);
    if (data) {
      data.forEach((element: any) => {
        if (default_phone_id) {
          if (element.phone_id == default_phone_id) {
            this.phoneDataFilled(formArray,element,type,default_phone_id);
          }else {
            this.phoneDataFilled(formArray,element,type,'');
          }
        } else {
          this.phoneDataFilled(formArray,element,type,'');
        }
      });
      return formArray;
    } else {
      formArray.push(this.fb.group({
        phone: type === 'notReq' ? new FormControl('', [Validators.minLength(14)]) : new FormControl('', [Validators.required, Validators.minLength(14)]),
        phone_type: ['Home'],
        phone_ext: '',
        phone_id: '',
        phone_class: "",
        isSelected: ""
      }));
      return formArray;
    }
  }

  private phoneDataFilled(formArray:any, element:any,type:string,default_phone_id:string){
    return formArray.push(this.fb.group({
      phone: type === 'notReq' ? new FormControl(element.phone ? this.formatPhoneNumber(element.phone) : "", [Validators.minLength(14)]) : new FormControl(element.phone ? this.formatPhoneNumber(element.phone) : "", [Validators.required, Validators.minLength(14)]),
      phone_type: element.phone_type ? element.phone_type[0].toUpperCase() + element.phone_type.slice(1) : '',
      phone_ext: element.phone_ext,
      phone_id: element.phone_id,
      phone_class: "",
      isSelected: element.phone_id == default_phone_id ? true : ""
    }));
  }

  // format the phone number
  private formatPhoneNumber(str: any) {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
    return null
  }

  /* function to bind the formarray data of address from API */
  public setExistingAddress(data: any, default_address_id: string, type : string) {
    const formArray: any = new FormArray([]);
    if (data?.length > 0) {
      data.forEach((element: any, index:number) => {
        if (default_address_id) {
          if (element.address_id == default_address_id) {
            this.addressDataFilled(formArray,element,type,default_address_id);
          }else{
            this.addressDataFilled(formArray,element,type,'');
          }
        } else {
          this.addressDataFilled(formArray,element,type,'');
        }
      });
      return formArray;
    } else {
      formArray.push(this.fb.group({
        address_type_id: ['Home'],
        address1: type === 'notReq' ? new FormControl('') : new FormControl('', [Validators.required]),
        address2: '',
        city: type === 'notReq' ? new FormControl('') : new FormControl('', [Validators.required]),
        state_id: type === 'notReq' ? new FormControl('') : new FormControl('', [Validators.required]),
        zip: type === 'notReq' ? new FormControl('') : new FormControl('', [Validators.required, Validators.minLength(5)]),
        county: new FormControl(''),

        address_id: '',
        apo: '',
        country_id: '',
        county_fips: '',
        zip_4: '',
        address_class: [''],
        isSelected: ""
      }));
      return formArray;
    }
  }

  private addressDataFilled(formArray:any, element:any,type:string,default_address_id:string){
    return formArray.push(this.fb.group({
      address_type_id: element.address_type_id ? element.address_type_id[0].toUpperCase() + element.address_type_id.slice(1) : '',
      address1: type === 'notReq' ? new FormControl(element.address1) : new FormControl(element.address1, [Validators.required]),
      address2: element.address2,
      city: type === 'notReq' ? new FormControl(element.city) : new FormControl(element.city, [Validators.required]),
      state_id: type === 'notReq' ? new FormControl(element.state_id) : new FormControl(element.state_id, [Validators.required]),
      zip: type === 'notReq' ? new FormControl(element.zip, [Validators.minLength(5)]) : new FormControl(element.zip, [Validators.required, Validators.minLength(5)]),
      county: new FormControl(element.county),

      address_id: element.address_id,
      apo: element.apo,
      country_id: element.country_id,
      county_fips: element.county_fips,
      zip_4: element.zip_4,
      address_class: [''],
      isSelected: element.address_id == default_address_id ? true : ''
    }));
  }

  /* format the social security  */
  public formatSocialSecurity(str: any) {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    };
    return null
  }

  public getAddresses(addressArray: any) {
    return addressArray.map((element: any) => ({
      address_type_id: element.address_type_id,
      address1: element.address1,
      address2: element.address2,
      city: element.city,
      state_id: element.state_id,
      zip: element.zip,
      county: element.county,

      address_id: element.address_id,
      apo: element.apo,
      country_id: element.country_id,
      county_fips: element.county_fips,
      zip_4: element.zip_4,
      default_address: element.isSelected,
    }));
  }

  public getEmails(emailArray: any) {
    return emailArray.map((element: any) => ({
      email: element.email,
      email_type: element.email_type,
      email_id: element.email_id,
      default_email: element.isSelected,
    }));
  }

  public getPhones(phoneArray: any) {
    return phoneArray.map((element: any) => ({
      phone: element.phone.replace(/\W/g, ""),
      phone_type: element.phone_type,
      phone_ext: element.phone_ext,
      phone_id: element.phone_id,
      default_phone: element.isSelected,
    }));
  }

  public getAdminAccountEmails(emailArray: any) {
    return emailArray.map((element: any) => ({
      email: element.email,
      email_type: element.email_type,
      email_id: element.email_id,
    }));
  }

  getAdminAccountPhones(phoneArray: any) {
    return phoneArray.map((element: any) => ({
      phone: element.phone.replace(/\W/g, ""),
      phone_type: element.phone_type,
      phone_ext: element.phone_ext,
      phone_id: element.phone_id,
    }));
  }

  //print page functions
  public printContent(printContents:any){
    var title = "Lead Adventage Pro";
    var popupWin = window.open('', '_blank', '')!;
    popupWin.document.open();
    popupWin.document.write('<html><head><title>' + title + '</title></head><body style="width:100%;height:100%;overflow-y:scroll;">' + printContents + '</body></html>');
    setTimeout(() => {
      popupWin.document.close();
      popupWin.focus();
      popupWin.print();
      popupWin.close();
    }, 300);
  }

  public getAdminAccountAddresses(addressArray: any) {
    return addressArray.map((element: any) => ({
      address_type_id: element.address_type_id,
      address1: element.address1,
      address2: element.address2,
      city: element.city,
      state_id: element.state_id,
      zip: element.zip,
      address_id: element.address_id,
      apo: element.apo,
      country_id: element.country_id,
      county: element.county,
      county_fips: element.county_fips,
      zip_4: element.zip_4
    }));
  }


  /* function to bind the formarray data of phone from API */
  public setExistingAdminPhone(data: any) {
    const formArray: any = new FormArray([]);
    if (data) {
      data.forEach((element: any) => {
        formArray.push(this.fb.group({
          phone: new FormControl(element.phone ? this.formatPhoneNumber(element.phone) : "", [Validators.minLength(14)]),
          phone_type: element.phone_type ? element.phone_type[0].toUpperCase() + element.phone_type.slice(1) : '',
          phone_ext: element.phone_ext,
          phone_id: element.phone_id,
          phone_class: "",
          isSelected: ""
        }));
      });
      return formArray;
    } else {
      formArray.push(this.fb.group({
        phone: new FormControl('', [Validators.minLength(14)]),
        phone_type: ['Home'],
        phone_ext: '',
        phone_id: '',
        phone_class: "",
        isSelected: ""
      }));
      return formArray;
    }
  }

  /* function to bind the formarray data of email from API */
  public setExistingAdminEmail(data: any) {
    const formArray: any = new FormArray([]);
    if (data) {
      data.forEach((element: any) => {
        formArray.push(this.fb.group({
          email: new FormControl(element.email, [Validators.pattern(validatorPatterns.EMAIL)]),
          email_type: element.email_type ? element.email_type[0].toUpperCase() + element.email_type.slice(1) : '',
          email_id: element.email_id,
          email_class: "",
          isSelected: ""
        }));
      });
      return formArray;
    } else {
      formArray.push(this.fb.group({
        email: new FormControl('', [Validators.pattern(validatorPatterns.EMAIL)]),
        email_type: ['Home'],
        email_id: "",
        email_class: "",
        isSelected: ""
      }));
      return formArray;
    }
  }


  /* function to bind the formarray data of address from API */
  public setExistingAdminAddress(data: any) {
    const formArray: any = new FormArray([]);
    if (data) {
      data.forEach((element: any) => {
        formArray.push(this.fb.group({
          address_type_id: element.address_type_id ? element.address_type_id[0].toUpperCase() + element.address_type_id.slice(1) : '',
          address1: new FormControl(element.address1),
          address2: element.address2,
          city: new FormControl(element.city),
          state_id: new FormControl(element.state_id),
          zip: new FormControl(element.zip, [Validators.minLength(5)]),

          address_id: element.address_id,
          apo: element.apo,
          country_id: element.country_id,
          county_fips: element.county_fips,
          zip_4: element.zip_4,
          address_class: [''],
          isSelected: ""
        }));

      });
      return formArray;
    } else {
      formArray.push(this.fb.group({
        address_type_id: ['Home'],
        address1: new FormControl(''),
        address2: '',
        city: new FormControl(''),
        state_id: new FormControl(''),
        zip: new FormControl('', [ Validators.minLength(5)]),

        address_id: '',
        apo: '',
        country_id: '',
        county_fips: '',
        zip_4: '',
        address_class: [''],
        isSelected: ""
      }));
      return formArray;
    }
  }
  public hasPermission(permission: string) {
    let permissions = this.authService.user_details?.rolePermission;

    let index = permissions?.findIndex((x: string) => x == permission);
    return (index > -1);
  }
  public setMenuExpand(isExpand: boolean) {
    this.expand.next(isExpand);
  }

  public getMenuExpand(): Observable<any> {
    return this.expand.asObservable();
  }

  public firstDayInPreviousMonth() {
    var date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  public lastDayInPreviousMonth() {
    var date = new Date();
    date.setDate(0);
    date.setMonth(date.getMonth());
    return date;
  }

  public phoneFormatter(event: any) {
    var x = event.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    event = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return event;
  }

  public formatDate(date: string) {
    return moment(date).format('MM/DD/YYYY');
  }

  // Retrieves a feature object from StateData for a given State ID
  public getStateInfoByID(stateID:string) {
    var singleStateInfoArray = [],
    SingleStateInfoObject = [];
    singleStateInfoArray = this.state_data.filter((obj: any) => {
      if (obj.abbreviation === stateID.toUpperCase()) {
        return obj;
      }
    });
    if (singleStateInfoArray.length > 0) {
      SingleStateInfoObject = singleStateInfoArray[0]
    };
    return SingleStateInfoObject
  };

  // Returns the full state name based on a State ID
  public getStateNameByID(stateID:string) {
    var stateInfo = this.getStateInfoByID(stateID);
    if (stateInfo && stateInfo.name) {
      return stateInfo.name;
    }
    return ""; // Return empty string if no match is found
  };

  public displayMMMTZZDateTime(date:Date) {
    var abbr={
      CDT :'Central Time',
      EDT :'Eastern Time',
      MDT :'Mountain Time',
      PDT :'Pacific Time'
    }
    var newdate = moment(date).tz("America/Chicago").format('MMM Do, YYYY h:mmA z');
    var lastPart = newdate.split(' ').pop();
    var changeDate;
    if(lastPart == 'CDT' || 'CST'){
      changeDate = (moment(date).tz("America/Chicago").format('MMM Do, YYYY h:mmA ')) + abbr.CDT;
    }else if(lastPart == 'EDT' || 'EST'){
      changeDate = (moment(date).tz("America/Chicago").format('MMM Do, YYYY h:mmA ')) + abbr.EDT;
    }else if(lastPart == 'MDT' || 'MST'){
      changeDate = (moment(date).tz("America/Chicago").format('MMM Do, YYYY h:mmA ')) + abbr.MDT;
    }else if(lastPart == 'PDT' || 'PST'){
      changeDate = (moment(date).tz("America/Chicago").format('MMM Do, YYYY h:mmA ')) + abbr.PDT;
    }
    return changeDate;
  }

  /* method for to display the today (if today's date exist) else MM/DD/YYYY format */
  public displayDate(date:Date){
    var mDate = moment(date);
    if(this.dateIsToday(mDate)){
      return "Today "+mDate.format("h:mmA");
    } else {
    return mDate.format('MM/DD/YYYY');
    }
  }

  private dateIsToday(mDate:any){
    return mDate.isSame(moment(), 'd');
  }

   /* method for to display the truncate the description */
  public truncateChar(text: string, charLimit?:number): string {
    let charlimit = charLimit ? charLimit : 40;
    if (!text || text.length <= charlimit) {
      return text;
    }
    let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, charlimit) + "...";
    return shortened;
  }

  public leadExportParseCSV(data: any, type: string) {
    let csv = '';
    let arrData = data.split('\n');
    let fileName;

    for (let i = 0; i < arrData.length; i++) {
      arrData[i] = arrData[i].split(',');
    }

    for (let i = 0; i < arrData.length; i++) {
      let row = '';
      for (let index in arrData[i]) {
        //Dont add , in the end
        if (parseInt(index) == arrData[i].length - 1)
        row += (type === 'leadExportPDPRetension')?'' + arrData[i][index] + '' : '"' + arrData[i][index] + '"';
        else row += (type === 'leadExportPDPRetension')? '' + arrData[i][index] + ',' : '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);
      row = row.replace(/(\r\n|\n|\r)/gm, ''); //remove all carriage returns from row

      if (row.length > 3)
        //empty string has length of 3 e.g "",
        csv += row + '\r\n';
    }

    if (csv == '') {
      console.error('Invalid data');
      return;
    }

    const timestampFormat: string = 'YYYY-MM-DD_HHmm-ss_X';
    //Create a file name
    switch (type) {
      case 'multiClientRxReport':
        let drugFullName = arrData[1][10] //pull the drug's full name
        let drugShortName = drugFullName ? drugFullName.split(' ')[0] + '_' : ''; //extract the first word
        fileName = 'Multi_Client_Rx_Report_' + drugShortName + '_' + this.authService.getToken().user_id + '_' + moment().format(timestampFormat) + '.csv';
        break;
      case 'multiClientPharmacyReport':
        let pharmacyName: string = arrData[1][10] || '   '; // pull up the first record and the pharmacy name
        let pharmacyShortName = pharmacyName.substring(0, 3).replace(/[^a-z\d]/i, ' '); // extract the first 3 characters and replace non-alphanumeric with spaces
        fileName = 'Multi_Client_Pharmacy_Report_' + pharmacyShortName + '_' + this.authService.getToken().user_id + '_' + moment().format(timestampFormat) + '.csv'
        break;
      case 'aepDisruptionReport':
        const requestedAgentUserID: string = arrData[1][1] || ''; // pull up the user id of the agent
        fileName = 'AEP_Disruption_Report_' + requestedAgentUserID + '_' + moment().format(timestampFormat) + '.csv'
        break;
      case 'leadExportPDPRetension':
        fileName = 'LeadExport_PDP_Retention_' + this.authService.getToken().user_id + '_' + moment().format(timestampFormat) + '.csv'
        break;
      default:
        fileName = 'LA_Pro_Lead_Export_' + this.authService.getToken().user_id + '_' + moment().format(timestampFormat) + '.csv';
        break;
    }

    var downloadLink = document.createElement('a');
    var blob: any = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  public unsavedChangesNotice(){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '30%',
      disableClose: true,
      data: {
        text: 'You have unsaved content, are you sure you want to exit?',
      },
    });
    dialogRef.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe((res) => {
      if (res) this.dialog.closeAll();
    });
  }

  public groupByPlanYear(data:any){
    return data.reduce((group:any, product:any) => {
      const { plan_year } = product;
      group[plan_year] = group[plan_year] ?? [];
      group[plan_year].push(product);
      return group;
    }, {});
  }

  public formDisclaimer(groupByCategory:any){
    let disclaimerData =
    '<div><p></p><p></p>';
  for (let item of Object.keys(groupByCategory)) {
    disclaimerData +='<div fxFlex="groupByCategory.length>1? 50% : 100%">'
      +'<p><b>For Plan Year '+item+'</b></p>';
    for (let type of groupByCategory[item]) {
      disclaimerData +=
        '<div>' +
        '<p>' +
        type.email_disclaimer_text_with_counts
        '</p>' +
        '</div>';
    }
    disclaimerData +='</div>' + '</div>';
  }
  return disclaimerData;
  }

  public callCMSPostCall(){
    this.dialog.open(SingleSignOnComponent, {
      width: '50%',
      autoFocus: false,
      data: {
        'data': {
          'user_id':this.authService.getToken().user_id,
          'login':this.authService.getToken().access_token
        },
        'url': environment.CMSLink,
        'quickLinkText': 'Click <b>Continue</b> to manually enter/update any Medicare Advantage and/or Part D Carriers / Plan Sponsors / Organizations you offer that are not displayed in your Profile on this platform. To see the carriers this platform is aware of that you offer, see your Profile. To find your Profile, click on your name on the upper right corner of any page on this platform and then select Profile. After you complete the manual entry process this platform will combine your manual entries with the Organization and Product counts calculated from the carriers listed in your Profile in order to display more accurate numbers in the CMS Disclaimer (see CMS DISCLAIMER TEXT below) emailed to beneficiaries via this platform and displayed on MA/PDP Quote Results and Lead Details screens showing how many Medicare Advantage/Part D “Organizations” and “Products” you offer in the beneficiary’s area. You can return here to <b>Quick Links</b> to update carriers any time by clicking on <b>CMS - Carriers + Plans</b>.'+
        '<br/><br/>'+
        '<i>CMS DISCLAIMER TEXT</i>:  “We do not offer every plan available in your area. Currently we represent [insert number of organizations] organizations which offer [insert number of plans] products in your area. Please contact http://Medicare.gov, 1–800–MEDICARE, or your local State Health Insurance Program to get information on all of your options.”'
      }
    });
  }

  public getQuoteURLs(filteredProducts: IProductFilter[]) {
    let quoteProductsURL: any = {
      'partd': 'mapartd',
      'medadvantage': 'mapartd',
      'medsup': 'medsup',
      'hospindem':'hospindem',
      'dental': 'dental',
      'fex': 'final',
      'life': 'life',
      'ltc': 'ltc',
    };

    if (filteredProducts.length > 0)
      filteredProducts.map(productFilter => {
        delete quoteProductsURL[productFilter.product_type_name];
      });

    let quoteProductsURLArray = Object.values(quoteProductsURL);
    const returnArray = quoteProductsURLArray
      .filter((value, index) => {
        return quoteProductsURLArray.indexOf(value) === index
      }) || [];

    return returnArray;
  }

  public reDirectQuote(type: string) {
    setTimeout(() => {
      this._leadService.leadSelected = false;
      this.router.navigate(['/quotes/' + type]);
    }, 5000);
  }

  public additionalFilterData(data: any, productType: string,leadId:string) {
    //AdditionalFilterData function used in MAPD and MedSup
    //Extract unique carrier names
    const uniqueCarriersArray= Array.from(new Set(data.map((item: any) => item.carrier_name)));
    //Sort the carrier names alphabetically, converting to lowercase for case-insensetive sorting
    uniqueCarriersArray.sort((a:any, b:any) => a.toLowerCase().localeCompare(b.toLowerCase()))
    //Create an array of unique sorted carrier_name object
    const carrierArray = uniqueCarriersArray.map((plan: any) => ({carrier_name: plan}));
    return {
      product_type: productType,
      lead_id:leadId,
      quoteResult: carrierArray
    }
  }

public countyStateAlert(stateCounties:any[]) {
  let text = '<ul>'
    stateCounties.forEach((state:any) => {
      // Add state name as a header
      text += `<span class='custom-ul font-600'>${state.state_name}</span>`;
      state.counties.forEach((county: any) => {
          // Add each county under the respective state
          text += `<li class='custom-li'>${county.county_name}</li>`;
      });
  });
    window.scroll(0, 0);
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '30%', disableClose: true, autoFocus: false,
      data: {
        'text': 'Our system indicates that the zip code you entered has more than one county. Please verify that the correct county is selected.<br>' + text,
        'type': 'ok'
      }
    });
}
}

export function ValidateDate(control: AbstractControl) {
  if (control.value) {
    let date = moment(control.value).format("DD");
    let year = moment(control.value).format('YYYY');
    if (date != '01' || year < '1965' || moment().add(2, 'years').format('YYYY') < year) {
      return { invalid: true };
    }
  }
  return null;
}

export function CheckInvalidDate(AC: AbstractControl) {
  if (AC && AC.value && !moment(AC.value, 'MM/DD/YYYY', true).isValid()) {
    return { 'dateVaidator': true };
  }
  return null;
}

export function CheckDobDate(control: AbstractControl) {
  if (control.value) {
    const date:any = moment(control.value);
    const year:any = moment(control.value).format("YYYY");
    const today:any = moment();
    if (date > today || year < moment(today).year() - 130) {
      return { 'invalidDate': true }
    }
  }
  return null;
}

export function formatLeadExportData(data:any) {
   data.forEach((notice: Notice) => {
    if(notice?.notice_text?.match(/<lapro-download/))
    notice.notice_text = notice?.notice_text
      .replace(
        /<lapro-download/,
        "<a style='cursor: pointer; text-decoration: underline; color: #23527c' class='exportLead'"
      )
      .replace(/<\/lapro-download>/g, '</a>');
  });
  return data
}

export function CompareStringsCaseInsensitive(str1: string, str2: string): boolean {
  if (str1 === null || str2 === null) return false; // Handle null values
  return str1.trim().localeCompare(str2.trim(), undefined, { sensitivity: 'base' }) === 0;
}


